import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'bootstrap'
import "@fortawesome/fontawesome-free/css/all"
import "perfect-scrollbar"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require.context('../images/global', true)